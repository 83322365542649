<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="formattedItem.ffinServicio"
      >
        <v-chip
          label
          small
          color="error"
        >
          Fin de servicio {{ formattedItem.ffinServicio|shortDate }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { stringToHslColor } from '@/utils/ui'
import { calculateMd5 } from '@/utils/crypto'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.ffinServicio = item.ffin_servicio
      item.title = item.concepto_cuota_descripcion
      item.subtitle = `Contratación: ${this.$options.filters.shortDate(item.fcontratacion)}`
      item.avatar = this.$options.filters.initials(item.concepto_cuota_descripcion)
      item.avatarColor = stringToHslColor(calculateMd5(item.concepto_cuota_descripcion))
      return item
    }
  },
}
</script>
